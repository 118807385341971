<template>
  <el-table
    v-infinite-scroll="load"
    :data="tableData"
    :row-class-name="tableRowClassName"
    :show-header="showHeader"
    :id="tableId"
    ref="tableRow"
    style="width: 100%"
    class="objects-table overflow-auto overflow-x-hidden"
    @row-click="$emit('onRowClick', $event)"
    @selection-change="$emit('onSelect', $event)"
    @select-all="$emit('onSelectAll', $event)"
  >
    <el-table-column v-if="options.hasCheckboxColumn" type="selection" min-width="3" />
    <el-table-column
      v-for="(item, idx) in titles"
      :key="idx"
      :property="item.prop"
      :label="item.label"
      :min-width="item.width"
      :header-align="item.headerAlign"
      :align="item.align"
    >
      <template slot="header">
        <div class="flex items-center" :class="[`justify-${item.align}`]">
          <span> {{ item.label }} </span>
          <SortIcon
            v-if="item.icon"
            :order-sort="item.orderSort"
            class="ml-1 cursor-pointer"
            @click.native="$emit('onSort', idx)"
          />
        </div>
      </template>
      <template slot-scope="scope">
        <div
          v-if="item.prop === 'sim_1_2'"
          class="flex flex-col text-xs font-normal leading-4 truncate"
        >
          <span v-for="(item, key) in scope.row[item.prop]" :key="key">
            {{ item }}
          </span>
        </div>
        <!-- <div v-else-if="item.prop === 'settings'" class="flex align-top gap-2"> -->
        <!--          <div @click.stop.prevent="onAccess">-->
        <!--            <p class="cursor-pointer hovered-icon opacity-0 curiousBlue text-sm">Доступ</p>-->
        <!--          </div>-->
        <!-- <SettingsIcon
            class="cursor-pointer hovered-icon opacity-0"
            @click.stop.prevent="onAccess"
          />
        </div> -->
        <div v-else-if="item.prop === 'settings'" class="flex">
          <el-popover
            v-if="showSettingButtons"
            placement="bottom"
            trigger="hover"
            :visible-arrow="false"
            popper-class="setting-popover"
            :append-to-body="true"
          >
            <template slot="reference">
              <span class="dots-icon" @click.stop.prevent="() => {}">
                <svg
                  width="4"
                  height="11"
                  viewBox="0 0 4 11"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0.761719 1.36035C0.761719 1.71191 0.887695 2.01074 1.13965 2.25684C1.3916 2.50879 1.69336 2.63477 2.04492 2.63477C2.39648 2.63477 2.69824 2.50879 2.9502 2.25684C3.20215 2.00488 3.32812 1.70605 3.32812 1.36035C3.32812 1.00293 3.20215 0.698242 2.9502 0.446289C2.69824 0.200195 2.39648 0.0771484 2.04492 0.0771484C1.69336 0.0771484 1.3916 0.200195 1.13965 0.446289C0.887695 0.698242 0.761719 1.00293 0.761719 1.36035ZM0.761719 5.45605C0.761718 5.80762 0.887695 6.10937 1.13965 6.36133C1.3916 6.61328 1.69336 6.73926 2.04492 6.73926C2.39648 6.73926 2.69824 6.61328 2.9502 6.36133C3.20215 6.10937 3.32812 5.80762 3.32812 5.45605C3.32812 5.10449 3.20215 4.80273 2.9502 4.55078C2.69824 4.30469 2.39648 4.18164 2.04492 4.18164C1.69336 4.18164 1.3916 4.30469 1.13965 4.55078C0.887695 4.80273 0.761719 5.10449 0.761719 5.45605ZM0.761718 9.56055C0.761718 9.91211 0.887695 10.2109 1.13965 10.457C1.3916 10.709 1.69336 10.835 2.04492 10.835C2.39648 10.835 2.69824 10.709 2.95019 10.457C3.20215 10.2109 3.32812 9.91211 3.32812 9.56055C3.32812 9.20898 3.20215 8.90723 2.95019 8.65527C2.69824 8.40332 2.39648 8.27734 2.04492 8.27734C1.69336 8.27734 1.3916 8.40332 1.13965 8.65527C0.887695 8.90723 0.761718 9.20898 0.761718 9.56055Z"
                    fill="#3A7CCA"
                  />
                </svg>
              </span>
            </template>
            <template>
              <div
                class="flex flex-col items-center justify-between gap-1 setting-popover__content"
              >
                <span
                  class="cursor-pointer"
                  @click.stop.prevent="$emit('onRowClick', scope.row)"
                >
                  {{ $t('archive.title_settings') }}</span
                >
                <span @click.stop.prevent="onEvents(scope.row)" class="cursor-pointer">
                  {{ $t('archive.title_events') }}
                </span>
                <template v-if="roleIsAdminOrHigh">
                  <span @click.stop.prevent="onOpenArchiveModal(scope.row)" class="cursor-pointer">
                    {{ $t('archive.title') }}
                  </span>
                </template>

              </div>
            </template>
          </el-popover>
        </div>
        <div v-else-if="item.prop === 'delete'">
          <DeleteIcon
            class="cursor-pointer hovered-icon"
            :class="{ recovered: options.recovered.includes(scope.row.id) }"
            @click.native.stop="
              $emit('onDelete', { item: scope.row, index: scope.$index })
            "
          />
        </div>
        <div v-else-if="item.prop === 'switch_actions'" class="flex justify-center gap-3">
          <UnitsSwitch
            :value="scope.row.is_active"
            :disabled="
              scope.row.status !== linksStatus.ACTIVE_ON &&
              scope.row.status !== linksStatus.ACTIVE_OFF
            "
            @onChange="$emit('onChangeLinkStatus', { item: scope.row, value: $event })"
            @click.native.stop
          />
          <CopyBlueIcon
            :class="{ clicked: isCopyClicked }"
            @click.native.stop="copyToClipboard(scope.row)"
          />
          <SettingsIcon class="cursor-pointer hovered-icon opacity-0" />
        </div>
        <div v-else-if="item.prop === 'is_blocked'">
          <div class="terminal-is-blocked-xyz" v-if="checkImeiIsBlocked(scope.row.imei)">
            <el-popover trigger="hover" popper-class="terminal-blocked-popover" placement="bottom">
              <TerminalBlockedIcon  slot="reference"/>
              <p>
              {{  $t('terminal.blocked')  }}
              </p>
            </el-popover>
          </div>
        </div>
        <div
          v-else
          :class="[
            'mainTitleColor',
            'font-semibold',
            'truncate',
            ...item.class,
            {
              [`link-status-${scope.row.status}`]:
                scope.row.status && item.prop === 'statusText'
            },
            { recovered: options.recovered.includes(scope.row.id) }
          ]"
        >
          {{ getValue(item, scope.row) }}
        </div>
      </template>
    </el-table-column>
    <div
      slot="append"
      v-if="!tableData.length"
      class="flex flex-col gap-2 justify-center items-center greyToneSecond mt-10"
    >
      <emptyIcon v-if="empty.icon" />
      {{ empty.text }}
    </div>
  </el-table>
</template>

<script setup>
import { computed, defineAsyncComponent, ref, watch } from 'vue'
import { useI18n } from '@/hooks/useI18n'

import SortIcon from '@/components/unitsNew/assets/icons/sort.vue'
import SettingsIcon from '@/components/unitsNew/assets/icons/settings.vue'
import DeleteIcon from '@/components/unitsNew/assets/icons/delete.vue'
import UnitsSwitch from '@/components/unitsNew/components/UnitsSwitch.vue'
import CopyBlueIcon from '@/components/unitsNew/assets/icons/copy-blue.vue'
import TerminalBlockedIcon from '@/assets/icons/TerminalBlockedIcon.vue'
import { createVuexHelpers } from 'vue2-helpers'
const { useGetters } = createVuexHelpers()
import { copyTextToClipboard } from '@/utils/helpers'
import { accessByRole } from '@/constants/roles'
import linksStatus from '@/enums/newUnits/links-status'
import { useStore } from '@/store/store'

import sections from '@/enums/newUnits/main-sections.js'

const tableRow = ref(null)
const $t = useI18n()
const emits = defineEmits(['onLoadData'])
const props = defineProps({
  titles: {
    type: Array,
    default: () => []
  },
  tableData: {
    type: Array,
    default: () => []
  },
  selectedItems: {
    type: Array,
    default: () => []
  },
  options: {
    type: Object,
    default: () => ({})
  },
  empty: {
    type: Object,
    default: () => ({})
  },
  showHeader: {
    type: Boolean,
    default: true
  },
  tableId: {
    type: String,
    default: 'table'
  },
  loadMore: {
    type: Boolean,
    default: false
  },
  activeTab: {
    type: String
  }
})

const Store = useStore()

const activeArchiveUnit = ref();

const onOpenArchiveModal = async (unit) => {
  activeArchiveUnit.value = unit
  Store.commit('archive/setOpenArchiveModal', true)
  Store.commit('archive/setUnit', unit)
  await fetchArchiveStates();
}

const fetchArchiveStates = async () => {
  await Store.dispatch('archive/getArchiveStates', { id: activeArchiveUnit.value.id })
}

const { terminals } = useGetters('terminal', ['terminals'])
const { role } = useGetters('login', ['role'])


const checkImeiIsBlocked = (imei) => {
  return isTerminals.value.includes(imei)
}

const showSettingButtons = computed(() => {
  return sections.OBJECTS === props.activeTab;
})

const isTerminals = computed(() => {
  const t = []
  terminals.value?.map(el => {
    if(el?.is_blocked === true) {
      t.push(el?.imei)
    }
  });
  return t
})


const onEvents = unit => {
  Store.commit('events/setIsEventsList', { unit, value: true })
  Store.dispatch('events/getEvents', {
    conditions: [
      {
        field: 'unit.id',
        value: unit.id
      }
    ],
    sortDesc: true
  })
}

const emptyIcon = computed(() => {
  return defineAsyncComponent(() =>
    import(`@/components/unitsNew/assets/icons/no-${props.empty.icon}.vue`)
  )
})

const isCopyClicked = ref(false)

const copyToClipboard = async item => {
  try {
    isCopyClicked.value = true

    await copyTextToClipboard(`https://${window.location.host}/locator?id=${item.id}`)

    setTimeout(() => {
      isCopyClicked.value = false
    }, 1000)
  } catch (err) {
    console.error('Failed to copy text: ', err)
  }
}

const getValue = (col, item) => {
  const property = col.prop.split('.')

  if (col.prop === 'userCount') {
    return item.users_count
  }

  if (col.prop === 'date_from' || col.prop === 'data_to') {
    if (item[col.prop] === $t('new_units.unspecified')) return item[col.prop]
    const date = item[col.prop].split(' ')

    if (date[1] && !col.fullTime) {
      return date[0]
    }

    return item[col.prop]
  }

  if (property.length === 1) return item[col.prop]

  let value = item

  property.forEach(key => {
    value = value[key]
  })

  return value
}

const tableRowClassName = ({ row, rowIndex }) => {
  if (props.options.recovered.includes(row.id)) {
    return 'recovered'
  }

  if (rowIndex === 0) {
    return 'isTop'
  }
}

const load = () => {
  emits('onLoadData')
}

const roleIsAdminOrHigh = computed(() => {
  return !accessByRole.ADMIN.includes(role.value)
})

watch(
  () => props.loadMore,
  () => {
    props.selectedItems.forEach(val => {
      tableRow.value.toggleRowSelection(val)
    })
  }
)
</script>

<style lang="scss">
@import '@/components/unitsNew/assets/styles/UnitsTable.scss';
.terminal-is-blocked-xyz {
  transform: translateX(10px);
}
</style>
<style lang="scss" scoped>
.dots-icon {
  padding: 2px 6px;
}
</style>
